body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    transition: all 0.2s;
}

html, body {
    outline: none;
    scroll-behavior: smooth;
    font-size: 62.5%;
    cursor: initial;
    font-family: 'Montserrat', sans-serif;
}

label {
    font-size: 2rem;
}

hr{
    width: 40%;
    margin: 20px auto;
}

h1, h2, h3, h4 {
    font-family: 'Anton', cursive;
    letter-spacing: 3px;
}

h1.principal {
    padding-top: 10%;
}

h1{
    font-size: 3.5rem;
}

h2 {
    font-size: 2.5rem;
}

a {
    font-size: 1.5rem;
    cursor: pointer;
    color: #0B8DC1;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #0B8DC1;
}

a:active {
    cursor: default;
}

p {
    font-size: 1.8rem ; 
}

table, th, tbody, tr, tfoot, td,thead, ul, ol {
    font-size: 1.5rem;
}

hr.styled {
	height: 10px;
	border: 0;
    box-shadow: 0 10px 10px -10px #8c8b8b inset;
    width: 100%;
}

hr.styled-reverse {
	height: 10px;
	border: 0;
    box-shadow: 0 -10px 10px -10px #8c8b8b inset;
    width: 100%;
}

button {
    font-size: 1.8rem;
}

.nes-btn.is-primary:hover, .nes-btn.is-primary:active {
    cursor: url("/images/cursors/cursor-click.png"), pointer !important;
    color: white;
    font-weight: bold;
    background-color: rgb(11, 116, 158);
}

.nes-btn.is-primary::after {
    box-shadow: inset -4px -4px rgb(11, 116, 158);
}

.nes-btn.is-primary:focus::after, .nes-btn.is-primary:hover::after {
    box-shadow: none;
}

.nes-link{
    cursor: url("/images/cursors/cursor-click.png"), pointer !important;
}

.nes-link:active {
    cursor: url("/images/cursors/cursor.png"), pointer !important;
}

.nes-link:hover{
    font-weight: bold;
}

.nes-octocat {
    position: absolute;
    bottom: 100%;
}

button#back-top {
    bottom: 50px;
    right: 5px;
    outline: none;
    opacity: 0.7;
    z-index: 500;
    position: fixed;
}

section {
    padding: 50px 0;
}

.section {
    margin-top: 30px;
}

section#error404, section#error403 {
    height: calc(100vh - 60px);
    position: relative;
}

section#error404 .container, section#error403 .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

section#error404 img {
    width: 18%;
}

/************** COULEURS ***************/

.section-grey {
    background-color: #d3d3d32e;
}

.text-blue {
    color: #0B8DC1;
}


/** FOOTER **/

footer {
    box-shadow: black 0px 15px 28px;
}

#text-footer {
    display: flex;
    align-items: center;
}

.footer-copyright {
    background-color: #26323a;
    margin-top: 7rem;
}

#text-footer p {
    width: 60%;
    margin: auto;
}

.footer-partners img.logo-partenaire {
    width: 70px;
    margin: auto 1.5rem;
}

@media screen and (max-width: 991px) {

    #backtop {
        display: none;
    }

    .container {
        padding: 3rem;
        width: 100% !important;
        max-width: unset;
        margin: auto;
    }

    p, li, span, a, button {
        font-size: 4rem;
    }

    h1 {
        font-size: 7rem;
    }

    h1.principal {
        padding-top: 210px;
    }

    h1#login-title {
        font-size: 3rem;
    }

    h2 {
        font-size: 7rem;
        margin: 8rem auto;
    }

    h3 {
        font-size: 6.5rem;
    }

    h4 {
        font-size: 5.5rem;
        margin: 5rem auto;
    }

    table, th, tbody, tr, tfoot, td,thead {
        font-size: 4rem;
    }

    .nes-btn {
        margin: 5rem auto;
    }

    .nes-icon {
        transform: scale(6);
    }

    .footerPart {
        margin: 10rem auto;
    }

    .icons-row {
        margin: 15rem auto;
        display: flex;
        justify-content: space-evenly;
    }
    
    #text-footer p {
        width: 100%;
    }

    #text-footer {
        display: block;
    }

    .footer-copyright p {
        font-size: 3.5rem;
    }

    section#login .form-group {
        width: 100%;
        padding: initial;
    }

    section#login input, section#login textarea, section#login button, section#login a {
        font-size: initial !important;
        margin: 3rem auto !important;
    }

    section#login p {
        font-size: 1.8rem;
    }
}

