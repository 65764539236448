.fa-laptop-house {
    font-size: 5rem;
    color: #0B8DC1;
}

section#work-home div.content {
    display: flex;
    justify-content: center;
}

section#work-home div.content img {
    width: 35%;
    min-width: 390px;
}

section#work-home div.content .card {
    width: 300px;
}

.split {
    display: flex;
    justify-content: center;
    align-items: center;
}

.split img.split-div {
    width: 25%;
}

#img-contrat {
    max-width: 400px;
    float: left;
    margin-right: 5rem;
}

#maintenance .content {
    margin: 5rem auto;
}

@media screen and (max-width: 992px) {

    section#work-home div.content {
        flex-direction: column;
    }
    
    section#work-home div.content .card {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
}
