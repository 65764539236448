/* CSS FOR STUFF LIST */

#good-stuff {
    background-color: #F8F9FA;
}

.card-content img {
    float: left;
    clear: both;
    width: 200px;
}

#megadiv img {
    width: 100px;
}

#good-stuff ul.pagination {
    margin: auto;
    width: min-content;
}

.card-header {
    font-size: 1.6rem;
    font-weight: bold;
}

small.date-add{
    text-align: center;
    font-size: 1rem;
    font-style: italic;
}

.card-header span {
    float: right;
    color: red;
    font-weight: bold;
    font-size: 1.7rem;
}

span#price {
    color: red;
    font-weight: bold;
    font-size: 1.7rem;
}

.card-content p {
    width: 70%;
    margin: 0px 50px 0 auto;
    padding: 35px 0 20px 0;
}

div.card-body {
    clear: both;
}

/* CSS FOR ONE OFFER */
section#stuff div.content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

section#stuff div.content img {
    width: 30%;
    border: 1px solid black;
    box-shadow: 3px 3px 8px;
}

section#stuff div.content p {
    width: 50%;
}

section#stuff div.content p span#phone_number {
    display: none;
}

@media screen and (max-width: 991px) {
    .card-header span, span#price {
        font-size: 4rem;
    }

    small.date-add {
        font-size: 2.5rem;
    }
}
