/* CSS DE LA PAGE DACCUEIL */

/******* PAGE DE GARDE *******/
#home-accueil {
    background: url('/images/home/accueil.jpg') no-repeat center;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
}

#hero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2rem;
}

#home-accueil .container {
    z-index: 99;
}

.hero-button {
    border-radius: 12px;
    padding: 0.4rem 5rem;
    transition: all 0.2s;
    text-decoration: none !important;
}

.hero-button-light {
    background-color: ghostwhite;
}

.hero-button-light:hover {
    background-color: rgb(213, 213, 219);
}

.hero-button-secondary {
    background-color: #0B8CC1;
    color: whitesmoke !important;
}

.hero-button-secondary:hover {
    background-color: #3d93b8;
}

#home-accueil a i {
    font-size: 5rem;
}

/******** PRESENTATION *********/

#home-presentation {
    display: flex;
    align-items: center;
    padding: 0;
}

#home-presentation .presentation {
    width: 50%;
    margin: auto 5%;
}

.img-container img {
    width: 100%;
}


/******* SERVICES *******/


.encart {
    width: 30%;
    /* margin: auto; */
}

.row-services {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row-reverse;
}

#home-services .container.iq-features {
    margin: 0;
    padding: 0;
    width: 50%;
}

.holderCircle {
    left: 70px;
}

.holderCircle { 
    width: 500px; 
    height: 500px; 
    border-radius: 100%; 
    margin: auto; 
    position: relative; 
}
.dotCircle { 
    width: 100%; 
    height: 100%; 
    position: absolute; 
    margin: auto; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    border-radius: 100%; 
    z-index: 20; 
}

.dotCircle  .itemDot { 
    display: block; 
    width: 80px; 
    height: 80px; 
    position: absolute; 
    background: #ffffff; 
    color: #0B8DC1; 
    border-radius: 20px; 
    text-align: center; 
    line-height: 80px; 
    font-size: 30px; 
    z-index: 3; 
    cursor: pointer; 
    border: 2px solid #e6e6e6; 
}

.dotCircle  .itemDot .forActive { 
    width: 56px; 
    height: 56px; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    display: none; 
}

.dotCircle  .itemDot .forActive::after { 
    content: ''; 
    width: 5px; 
    height: 5px; 
    border: 3px solid rgb(12, 106, 143); 
    bottom: -31px; 
    left: -14px; 
    filter: blur(1px); 
    position: absolute; 
    border-radius: 100%; 
}

.dotCircle  .itemDot .forActive::before { 
    content: ''; 
    width: 6px; 
    height: 6px; 
    filter: blur(5px); 
    top: -15px; 
    position: absolute; 
    transform: rotate(-45deg); 
    border: 6px solid rgb(30, 133, 173); 
    right: -39px; 
}

.dotCircle  .itemDot.active .forActive { 
    display: block; 
}

.round { 
    position: absolute; 
    left: 40px; 
    top: 45px; 
    width: 410px; 
    height: 410px; 
    border: 2px dotted lightgray; 
    border-radius: 100%; 
    -webkit-animation: rotation 100s infinite linear; 
}

.dotCircle .itemDot:hover, .dotCircle .itemDot.active { 
    color: #ffffff; 
    transition: 0.5s;   /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7d4ac7+0,a733bb+100 */ 
    background: #0B8DC1; /* Old browsers */ 
    background: -moz-linear-gradient(left, #0B8DC1 0%, #3594d3e3 100%); /* FF3.6-15 */ 
    background: -webkit-linear-gradient(left, #0B8DC1 0%, #3594d3e3 100%); /* Chrome10-25,Safari5.1-6 */ 
    background: linear-gradient(to right, #0B8DC1 0%, #3594d3e3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ 
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d4ac7', endColorstr='#a733bb', GradientType=1); /* IE6-9 */ 
    border: 2px solid #ffffff; 
    -webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13); 
    -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13); 
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .13); 
}

.dotCircle .itemDot { 
    font-size: 40px; 
    cursor: url('/images/cursors/cursor-click.png'), pointer;
}

.dotCircle .itemDot:active { 
    font-size: 40px; 
    cursor: url('/images/cursors/cursor.png'), pointer;
}

.contentCircle { 
    width: 250px; 
    border-radius: 100%; 
    color: #222222; 
    position: relative; 
    top: 150px; 
    left: 50%; 
    transform: translate(-50%, -50%); 
}

.contentCircle .CirItem { 
    border-radius: 100%; 
    color: #222222; 
    position: absolute; 
    text-align: center; 
    bottom: 0; 
    left: 0; 
    top: 0; 
    right: 0; 
    opacity: 0; 
    transform: scale(0); 
    transition: 0.5s; 
    font-size: 15px; 
    width: 100%; 
    height: 100%; 
    margin: auto; 
    line-height: 250px; 
}

.CirItem.active { 
    z-index: 1; 
    opacity: 1; 
    transform: scale(1); 
    transition: 0.5s; 
}

.contentCircle .CirItem i { 
    font-size: 180px; 
    position: absolute; 
    top: 0; 
    left: 50%; 
    margin-left: -90px; 
    color: #000000; 
    opacity: 0.1; 
}

.title-box .title { 
    font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    z-index: -1;
    font-size: 2rem;
}

.title-box span { 
    text-shadow: 0 10px 10px rgba(0, 0, 0, .15); 
    font-weight: 800; 
    color: #0B8DC1; 
}

.title-box p {
    font-size: 1.5rem; 
    line-height: 1.5em; 
}

#services-phone {
    display: none;
}

#img-greenIT {
    max-width: 200px;
    float: right;
}
/********* BONNES AFFAIRES *********/

section#home-bonnesaffaires {
    box-shadow: black 0px 5px 30px;
}

/******** LAST NEWS ! *********/


/****** GOOGLE REVIEWS *******/

div.elfsight-app-630c854f-c4a2-4a45-acee-d984c4b6f677 {
    margin-bottom: 10px;
    width: 60%;
    margin: 50px auto;
}


/* Question 'ce que les gens pensent de nsi' */
.jsx-2615826576 {
    font-size: 1.7rem;
}


/* RESPONSIVE */


@media screen and (max-width: 1024px) {
    .row-services .encart {
        width: 95%;
        margin-top: 15rem;
    }

    #hero {
        width: 100%;
    }

    .row-services {
        flex-direction: column;
    }

    #services-phone {
        display: inherit;
    }

    .dotCircle {
        position: unset;
    }

    .service .itemIcon {
        display: none;
    }

    .service {
        margin: 3rem;
        margin-bottom: 6rem;
    }

    .service h3 {
        color: #0B8DC1;
    }

    .itemText {
        padding: 1rem;
    }

    .itemDotPhone {
        display: block;
        width: 80px;
        height: 80px;
        background: #ffffff;
        color: #0B8DC1;
        border-radius: 20px;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
        z-index: 3;
        border: 2px solid #e6e6e6;
        position: initial;
        cursor: initial;
    }

    #home-services .container.iq-features {
        display: none;
    }
    
}

@media screen and (max-width: 991px) {
    
    #home-accueil .container img {
        width: 90%;
        max-width: 550px;
    }

    #home-accueil .container h1 {
        font-size: 11rem;
    }

    #home-accueil .container {
        top: 25%;
    }

    #home-presentation .img-container img {
        display: none;
    }

    #home-presentation div.presentation {
        width: unset;
        margin: auto 2%;
    }

    #home-accueil a i {
        font-size: 10rem;
    }

    .nes-octocat {
        font-size: 5rem;
    }

    #home-services .container.iq-features {
        display: none;
    }

    #home-presentation {
        display: block;
    }

   

}
