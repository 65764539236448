/** TARIFS **/

#tarifs .img-container {
    background: url("/images/tarifs/NSInformatique-Banniere-Home-compressed.png") center center no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 300px;
}

h2.in-image {
    position: relative;
    top: 35%;
}

span.old-price {
    font-size: 1.5rem !important;
    color: black !important;
    text-decoration: line-through !important;
}