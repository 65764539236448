/* CONTACT */

section#contact {
    margin: 0;
    padding: 0;
}

#contact .img-container {
    background: url('https://images.unsplash.com/photo-1547394765-185e1e68f34e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80') center no-repeat;
    background-size: cover; 
    height: 300px;
    background-attachment: fixed;
    position: relative;
    top: 80px;
}

#contact h1{
    padding-top: 10%;
}

#contact .img-container h2.in-image {
    position: relative;
    top: 35%;
    margin: 0;
}

div.parts {
    display: flex;
    padding: 0;
    margin: 0;
}

div#form-part {
    width: 50%;
    height: 100vh;
    margin: 0;
    padding: 0;
}

div#plan-part {
    width: 50%;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: url('/images/contact/contact.jpg') center no-repeat;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div#plan-part .contact-infos, div#plan-part .horaires {
    width: 50%;
    text-align: left;
    color: white;
    margin: 20px auto 20px auto;
}

div#plan-part hr {
    background-color: white;
    width: inherit;
}

div#plan-part .contact-infos i {
    font-size: 1rem;
}

div#plan-part .contact-infos p, div#plan-part .horaires p {
    font-size: 1.5rem;
}

.invalid-feedback {
    font-size: 1.1rem;
    margin: 0;
    padding: 0;
} 

.row {
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
    height: 50px;
}

.card-header {
    font-size: 2rem;
}

.form-control, .nes-container p, .nes-container p.title {
    font-size: 1.5rem;
}

.form-group {
    width: 50%;
    padding: 5px;
}

#textarea-message {
    width: 100%;
}

#textarea-message .form-group {
    width: 100% !important;
}

#textarea-message .form-group textarea {
    height: 100px;
}

#contact .card {
    width: 60%;
    margin: 27% auto;
    box-shadow: 5px 5px 7px -7px black;
}

#contact form button {
    width: 100%;
    margin: 5px 0 5px 0;    
    padding: 0;
}

#contact .card-body p {
    font-size: 1.5rem;
    margin: 20px 0 10px 0;
}

.icon-list {
    text-align: center;
}

@media screen and (max-width: 991px) {
    div.parts {
        flex-direction: column;
        align-items: center;
    }
    div#form-part, div#plan-part {
        width: 100%;
        height: 90vh;
    }

    div#plan-part .contact-infos, div#plan-part .horaires {
        width: 100%;
        padding: 5rem;
    }
    
    div#plan-part .contact-infos p, div#plan-part .horaires p, div#plan-part .contact-infos i {
        font-size: 5rem;
    }

    div#plan-part {
        justify-content: space-evenly !important;
    }

    div#plan-part h2 {
        font-size: 7rem;
    }

    #contact .img-container {
        top: 24rem;
    }

    #contact .card {
        width: 100%;
        margin: 40% auto;
    }

    #contact .card-body p {
        font-size: 5rem;
        margin: 20px 0 10px 0;
    }

    #contact .row {
        display: flex;
        flex-direction: column;
    }

    #contact .row .form-group {
        width: 100%;
    }

    input, textarea {
        font-size: 5rem !important;
        margin: 3rem auto;
    }

    #textarea-message .form-group textarea {
        height: 30rem;
    }

    .card-header {
        font-size: 6rem !important;
    }

    #contact form button {
        width: 100%;
        margin: 3rem auto;
        padding: 4rem;
    }

    .nes-text.is-error {
        font-size: 3rem;
    }
}

@media screen and (max-width: 768px) {
    div.parts {
        flex-direction: column;
        align-items: center;
    }

    #contact .card {
        width: 80%;
        margin: auto;
    }

    div.row {
        margin-right: initial;
        margin-left: initial;
        flex-direction: column;
        width: 100%;
    }
}




