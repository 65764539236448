.navbar {
    position: fixed;
    width: 100%;
    z-index: 100;
}

.nav-link.active {
    font-weight: bold;
    color: #0B8DC1;
}

.logo {
    width: 180px;
    transition: all 0.2s;
}

.navbar-collapse{
    justify-content: center;
}

nav {
    box-shadow: black 0px 0px 30px;
}

#top-header {
    display: flex;
    justify-content: center;
    background-color: #26323a;
}

#top-header p {
    font-size: 1.2rem;
}

.navbar-scrolled {
    width: 10% !important;
    transition: all 0.2s;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #0B8DC1;
}

/* RESPONSIVE */

@media screen and (max-width: 991px) {
    
    .navbar-light .navbar-nav .nav-link {
        text-align: center;
        font-size: inherit;
    }

    #top-header {
        display: block;
    }
    

    #top-header p {
        font-size: 3.5rem;
    }

    .navbar-light .navbar-brand {
        width: 100%;
    }

    .navbar-light .navbar-brand img{
        display: none;
    }
}